<div class="container">
    <div class="domain-target" *ngFor="let domain of _ss.getWorkShopSettings()?.domains; let i = index">
        <div class="domain-content">
            <span> {{domain.domain}}</span>
        </div>
        <div class="domain-theme"  [ngStyle]="{'background-image':'url('+ _ss.imgURL + domain.image + ')'}">
            <div class="overlay">
                <h1>{{domain.brand_name}}</h1>
                <div class="ribbon" (click)="changeDomainStatus(domain.id)" *ngIf="!domain.status">Desactivado</div>
                <div class="ribbon active" (click)="changeDomainStatus(domain.id)" *ngIf="domain.status">Activado</div>
            </div>
        </div>
        <div class="domain-btn">
            <a class="icon" (click)="setDomainId(domain.id)"  target="_blank">
                <fa-icon [icon]="faPencil"></fa-icon> <span>EDITAR</span>
            </a>
            <a class="icon" href="http://{{domain.domain}}/#/inicio?key={{domain.key}}" target="_blank">
                <fa-icon [icon]="faEye"></fa-icon> <span>VER</span>
            </a>
        </div>
    </div>
</div>
<app-configuration-site *ngIf="domain" [domain]="domain"></app-configuration-site>