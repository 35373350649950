<div class="form">
    <div class="social-media">
        <div class="" *ngFor="let social of socialTypes">
            <div class="icon">
                <span class="social-icon" [inlineSVG]="social.image"></span>
            </div>
            <input type="text" name="facebook" placeholder="http://" [(ngModel)]="social.link" />
            <div class="checkbox">
                <label for="status">Mostrar</label>
                <input name="status" type="checkbox" (change)="social.status = !social.status" [checked]="social.status" />
            </div>
        </div>
    </div>
</div>