<div class="container">
    <div class="filter">
        <div class="search-container">
            <div class="search">
                <span class="icon">
                        <fa-icon [icon]="faSearch"></fa-icon>
                    </span>
                <input name="search" type="search" [(ngModel)]="search.text" placeholder="Buscar" (keyup)="filter()" />
                <span *ngIf="showClearFilter" class="clear" (click)="clearFilter()">
                    <fa-icon [icon]="faClose"></fa-icon>
                </span>
                <span class="arrow" (click)="showSearch()">
                    <fa-icon [icon]="faArrow"></fa-icon>
                </span>
            </div>
            <div class="container" [ngClass]="{show : isOpened}">
                <div class="row">
                    <div>
                        <label for="start_date">
                            Estado:
                        </label>
                        <select name="status" [(ngModel)]="search.status" (change)="filter()">
                                <option value="3">Todas</option>
                                <option value="1">Activadas</option>
                                <option value="0">Desactivadas</option>
                                <option value="4">Destacadas</option>
                            </select>
                    </div>
                </div>
                <div class="row">
                    <div>
                        <label for="start_date">
                                Fecha desde:
                            </label>
                        <input type="date" name="start_date" [(ngModel)]="search.create_date" (change)="filter()" />
                    </div>

                    <div>
                        <label for="end_date">
                                Fecha hasta:
                            </label>
                        <input type="date" name="end_date" [(ngModel)]="search.expiration_date" (change)="filter()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="promotions">
            <div class="promotion-alert"  *ngIf="tempPromotions.length == 0">
                <span>Actualmente no tiene ninguna promoción creada!</span>
            </div>
            <div class="promotion" *ngFor="let promotion of tempPromotions">
                <div class="image-container">
                    <span class="star" [ngClass]="{featured: promotion.featured}" (click)="changePromotionFeatured(promotion.id)">
                    <fa-icon [icon]="faStar"></fa-icon>
                </span>
                    <span class="close" (click)="delPromotion(promotion.id)">
                    <fa-icon [icon]="faClose"></fa-icon>
                </span>
                    <div class="ribbon" (click)="changePromotionStatus(promotion.id)" *ngIf="!promotion.status">Desactivada</div>
                    <div class="ribbon-active" (click)="changePromotionStatus(promotion.id)" *ngIf="promotion.status">Activada</div>
                    <div class="overlay"></div>
                    <div class="image" [ngStyle]="{'background-image': 'url(' + _ap.imgURL + promotion.image + ')'}">
                    </div>
                </div>
                <div class="container">
                    <h1>{{promotion.title}}</h1>
                    <p [innerHTML]="promotion.description"></p>
                </div>
                <button class="btn" (click)="setPromotion(promotion.id)">Modificar</button>
            </div>
        </div>
        <div class="form" [ngClass]="{open: isNewPromotion}" #formpromotion>
            <div class="title">
                <h1 *ngIf="promotion.id == null">Nueva Campaña</h1>
                <h1 *ngIf="promotion.id != null">Modificar Campaña</h1>
                <span class="close" (click)="isNewPromotion = false">
                    <fa-icon [icon]="faClose"></fa-icon>
                </span>
            </div>
            <div class="container">
                <label for="title">Título</label>
                <input type="text" name="title" [(ngModel)]="promotion.title" placeholder="Título" />
                <label for="title">Descripción</label>
                <angular-editor [placeholder]="'Introduce una descripción...'" [(ngModel)]="promotion.description" [config]="editorConfig"></angular-editor>
                <div class="row">
                    <div class="form-group">
                        <label for="title">Inicio</label>
                        <input type="date" name="title" [(ngModel)]="promotion.start_date" />
                    </div>
                    <div class="form-group">
                        <label for="title">Fin</label>
                        <input type="date" name="title" [(ngModel)]="promotion.expiration_date" />
                    </div>
                </div>
                <div class="uploading">
                    <label for="upload-file" class="custom-file-upload">
                    <i class="fa fa-cloud-upload"></i>
                  Seleccione una imagen</label>
                    <span class="file-name">{{ filename }}</span>
                    <input id="upload-file" #selectedPicture type="file" name="photo" ng2FileSelect [uploader]="uploader" />
                    <div class="progress" *ngIf="isuploadingProgress">
                        <p style="padding: 0; margin: 0">Archivo subido</p>
                        <div class="progress-bar" [style.width]="uploadStatusProgress">
                            <p class="progress-content" role="progressbar"></p>
                        </div>
                        <span class="progress-num">{{ uploadStatusProgress }}</span>
                        <!-- <button class="cancelUploadBtn" (click)="cancelUpload()">Cancel</button> -->
                    </div>
                    <div class="drop-zone" [ngClass]="{'file-over': hasBaseDropZoneOver}" ng2FileDrop [uploader]="uploader" (click)="fileInput.click()">

                        Por favor, elige una imagen
                        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" />
                    </div>
                </div>
                <button *ngIf="promotion.id != null" class="btn" (click)="save()">EDITAR Y GUARDAR</button>
                <button *ngIf="promotion.id == null" class="btn" (click)="save()">CREAR Y GUARDAR</button>
            </div>
        </div>
    </div>
</div>