export class Promotion {
  // Campos obligatorios implementados por la interfaz
  id: number;
  title: string;
  description: string;
  image: string;
  banner_image: string;
  start_date: any;
  expiration_date: any;
  status: boolean;
  featured: boolean;


    constructor (p?: Promotion) {
      // this.sales = a && a.sales || [new Sale()];
      this.id = p && p.id;
      this.title = p && p.title;
      this.description = p && p.description;
      this.image = p && p.image;
      this.banner_image = p && p.banner_image;
      this.start_date = p && p.start_date;
      this.expiration_date = p && p.expiration_date;
      this.status = p && p.status;
      this.featured = p && p.featured;
    }
}
