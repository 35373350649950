<div *ngIf="page">
    <div class="form">
        <div>
            <label for="title">Pagina</label>
            <input type="text" name="title" [(ngModel)]="page.name" placeholder="Título" />
        </div>
        <div>
            <label for="banner_title">Titulo Banner</label>
            <input type="text" name="banner_title" placeholder="titulo del banner" [(ngModel)]="page.banner_title" />
        </div>
        <div class="actual-banner" *ngIf="page.banner_image" [ngStyle]="{'background-image':'url('+ _ss.imgURL + page.banner_image + ')'}">

        </div>
        <div class="no-image" *ngIf="!page.banner_image">

        </div>
        <div class="uploading">
            <label for="upload-file" class="custom-file-upload">
              <i class="fa fa-cloud-upload"></i>
            Seleccione una imagen</label>
            <span class="file-name">{{ filename }}</span>
            <input id="upload-file" #selectedPicture type="file" name="photo" ng2FileSelect [uploader]="uploader" />
            <div class="progress" *ngIf="isuploadingProgress">
                <p style="padding: 0; margin: 0">Archivo subido</p>
                <div class="progress-bar" [style.width]="uploadStatusProgress">
                    <p class="progress-content" role="progressbar"></p>
                </div>
                <span class="progress-num">{{ uploadStatusProgress }}</span>
                <!-- <button class="cancelUploadBtn" (click)="cancelUpload()">Cancel</button> -->
            </div>
            <div class="drop-zone" [ngClass]="{'file-over': hasBaseDropZoneOver}" ng2FileDrop [uploader]="uploader" (click)="fileInput.click()">

                Por favor, elige una imagen
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" />
            </div>
        </div>
        <div *ngIf="page.id_page_type == 5 || page.id_page_type == 4">
            <label for="content_title">Titulo Contenido</label>
            <input type="text" name="content_title" placeholder="titulo del contenido" [(ngModel)]="page.content_title" />
        </div>
        <div *ngIf="page.id_page_type == 4">
            <label for="content_title">Subtitulo Contenido</label>
            <input type="text" name="content_title" placeholder="titulo del contenido" [(ngModel)]="page.content_subtitle" />
        </div>
        <div *ngIf="page.id_page_type == 5 || page.id_page_type == 4">
            <label for="content_description">Descripcion contenido</label>
            <angular-editor [placeholder]="'Introduce una descripción...'" [(ngModel)]="page.content_description" [config]="editorConfig"></angular-editor>
        </div>
        <!--
 <div>
      <label for="content_image">Imagen contenido</label>
      <input type="text" name="content_image" placeholder="Imagen contenido" [(ngModel)]="page.content_image"/>
    </div>

    -->

    </div>
</div>