import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Vehicle } from 'src/app/models/vehicle';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SharingService } from 'src/app/services/sharing.service';
import { FileUploader } from 'ng2-file-upload';
import { ApserviceService } from 'src/app/services/apservice.service';
import { NotifierService } from 'angular-notifier';
import { NgForm } from '@angular/forms'; // Importa NgForm

@Component({
  selector: 'app-voform',
  templateUrl: './voform.component.html',
  styleUrls: ['./voform.component.scss']
})
export class VoformComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() isFormOpen: boolean;
  @Output() closeClicked = new EventEmitter<any>();
  @Output() updateVehicle = new EventEmitter<void>();
  @ViewChild('selectedPicture', { static: false }) selectedPicture: ElementRef;
  faClose = faTimes;
  saveSubscription;
  newSubscription;
  activeTab: string = "form";
  tabs = [
    { label: 'Descripción', active: true, content: 'Contenido de la descripción.' },
    { label: 'Equipamiento', active: false, content: 'Contenido del equipamiento.' },
    { label: 'Extras', active: false, content: 'Contenido de los extras.' },
    { label: 'Accesorios', active: false, content: 'Contenido de los accesorios.' }
  ];
  submitted: boolean = false;

   // FileUpload Service Variables
   uploader:FileUploader;
   isuploadingProgress: boolean;
   uploadStatusProgress: string;
   saveWithImage:boolean = false;
   filename: string;
   hasBaseDropZoneOver:boolean;
   hasAnotherDropZoneOver:boolean;

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    public _ns: NotifierService
  ) {
    this.uploader = new FileUploader({
      url: this._ss.adminURL + 'upload',
      method: 'POST',
      queueLimit: 5,
      allowedMimeType: ['image/png', 'image/jpeg'],
      itemAlias: 'photo',
    });

     //override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.

     this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; 
      this.filename = file.file.name;
      if (this.uploader.queue.length >= 1) {
        this.saveWithImage = true;            
      }
    };

    this.uploader.onBeforeUploadItem = (file)=>{
     console.log(file);
    };

    //overide the onCompleteItem property of the uploader so we are 
    //able to deal with the server response.

    this.uploader.onProgressItem = (fileItem: any, progress: any)=>{
      this.isuploadingProgress = true;
      this.uploadStatusProgress = progress+'%';
    }

    this.uploader.onCompleteItem = (item:any, data:any, status:any, headers:any) => {
    data = JSON.parse(data);
    if(data.succes === 'OK'){
        console.log(data);
        let filename = data.file.filename;
        this.vehicle.images.push(filename);
        this.clearSelectedPicture();
        if (this.vehicle.id) {
          this.isFormOpen = true;
          this.modifyVehicle();
        } else {
          this.isFormOpen = false;
          this.addVehicle();
        }
        this.closeClicked.emit(this.isFormOpen);
      }else{
        console.log("Se ha producido un error");
      }
    }

    this.uploader.onErrorItem = (item: any, response: string, status: number, headers:any) =>{
      console.log(response);
    }

    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any) => {
      console.log('Imagen subida');
      if (this.selectedPicture) {
        this.selectedPicture.nativeElement.value = '';
      }
    };
  }

  ngOnInit(): void {
    this._ss.saveButton = false;
    this._ss.newButton = true;
    this.newSubscription = this._ss.$create.subscribe(() => {
      console.log("creando vehicle....");
      this.activeTab = 'form';
      this.isFormOpen = true;
      this.closeClicked.emit(this.isFormOpen);
      this.clearSelectedPicture();
      this.vehicle = new Vehicle();
    });  
    
    if(this.vehicle.id){
      this.activeTab = 'form';
    }
  }

  selectTab(selectedTab) {
    this.tabs.forEach((tab) => {
      tab.active = false;
    });
    selectedTab.active = true;
  }

  handleSubmit(form: NgForm) {
    console.log('guardando...');
    console.log(form);
    this.submitted = true; // Marca el formulario como enviado
    if(form.valid && this.saveWithImage){
      this.uploader.uploadAll();
    }else{
      if (form.valid) {
        console.log("entra aqui no hay imagen...");
        if (this.vehicle.id) {
          this.modifyVehicle();
          this.closeClicked.emit(true);
        } else {
          this.addVehicle();
          this.closeClicked.emit(false);
        }
      }else{
        this._ns.notify('error', 'Campos obligatorios');
        this.closeClicked.emit(true);
      }
    }
  }

  addVehicle() {
    this._ap.addVoVehicle(this.vehicle).subscribe((response) => {
      if (response.result === 'OK') {
        this._ns.notify('success', 'Creado correctamente');
        this.clearSelectedPicture();
        this.updateVehicle.emit();
      } else {
        this._ns.notify('error', 'Error: Se ha producido un error');
      }
    }, (e) => {
      this._ns.notify('error', e.message);
    });
  }

  modifyVehicle() {
    this._ap
      .modifyVoVehicle({
        ...this.vehicle,
        first_date_plate: new Date(this.vehicle.first_date_plate)
          .toISOString()
          .slice(0, 19)
          .replace('T', ' '),
      })
      .subscribe((response) => {
        if (response.result === 'OK') {
          this._ns.notify('success', 'Guardado correctamente');
          this.clearSelectedPicture();
          this.updateVehicle.emit();
        } else {
          this._ns.notify('error', 'Error: Se ha producido un error');
        }
      }, (e) => {
        this._ns.notify('error', e.message);
      });
  }

  ngOnDestroy(): void {
    this.newSubscription.unsubscribe();
  }

  clearSelectedPicture() {
    this.filename = '';
    this.isuploadingProgress = false;
    this.uploadStatusProgress = '';
    if(this.selectedPicture){this.selectedPicture.nativeElement.value = ''};
    this.uploader.clearQueue();
    this.saveWithImage = false;
  }

  closeForm() {
    console.log("cerrando...");
    this.closeClicked.emit(false);
  }

  selectFormTab(value) {
    this.activeTab = value;
  }

  delImage(item){
    console.log(item);
    this.vehicle.images = this.vehicle.images.filter(element => element !== item);
    this.modifyVehicle();
  }
}