<div class="container">
    <!-- Sliders -->
    <h1>Sliders <span class="info">(Máximo 6 imágenes)</span><span class="close" [ngClass]="isOpenSlider ? 'open' : ''" (click)="closeSlider()"><fa-icon [icon]="faChevronDown"></fa-icon></span></h1>
    <div class="slider-container" [ngClass]="isOpenSlider ? 'open' : ''"  >
        <div class="bar-btn" *ngIf="_ss.sliders.length < 6">
            <span class="btn-add" (click)="addSlider()">NUEVO SLIDER <fa-icon [icon]="faPlus"></fa-icon></span>
        </div>
        <div class="content">
            <span *ngIf="_ss.banners.length == 0">Actualmente no tiene ningún slider creado!</span>
            <div class="slider" *ngFor="let slider of _ss.sliders" (click)="setSlider(slider)" [ngStyle]="{'background-image':'url('+ _ss.imgURL + slider.image + ')'}">
                <div class="overlay"></div>
                <div class="ribbon" (click)="changeSliderStatus(slider.id)" *ngIf="!slider.status">Desactivada</div>
                <div class="ribbon-active" (click)="changeSliderStatus(slider.id)" *ngIf="slider.status">Activada</div>
                <span class="delete" (click)="deleteSlider(slider.id)">
                    <fa-icon [icon]="faClose"></fa-icon>
                </span>
            </div>
        </div>
    </div>

    <app-slider-modify [slider]="slider"></app-slider-modify>

    <!-- Banners -->
    <h1>Banners <span class="info">(Máximo 6 imágenes)</span><span class="close" [ngClass]="isOpenBanner ? 'open' : ''" (click)="closeBanner()"><fa-icon [icon]="faChevronDown"></fa-icon></span></h1>
   
   
    <div class="slider-container" [ngClass]="isOpenBanner ? 'open' : ''"  >
        <div class="bar-btn" *ngIf="_ss.banners.length < 6">
            <span class="btn-add" (click)="addBanner()">NUEVO BANNER <fa-icon [icon]="faPlus"></fa-icon></span>
        </div>
        <div class="content">
            <span *ngIf="_ss.banners.length == 0">Actualmente no tiene ningún banner creado!</span>
            <div class="slider" *ngFor="let banner of _ss.banners" (click)="setBanner(banner)" [ngStyle]="{'background-image':'url('+ _ss.imgURL + banner.image + ')'}">
                <div class="overlay"></div>
                <div class="ribbon" (click)="changeStatusBanner(banner.id)" *ngIf="!banner.status">Desactivada</div>
                <div class="ribbon-active" (click)="(banner.id)" *ngIf="banner.status">Activada</div>
                <span class="delete" (click)="deleteBanner(banner.id)">
                    <fa-icon [icon]="faClose"></fa-icon>
                </span>
            </div>
        </div>
    </div>

    <app-banner-modify [banner]="banner"></app-banner-modify>

</div>