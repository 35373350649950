export class Page {
  // Campos obligatorios implementados por la interfaz
  id: number;
  id_page_type: number;
  id_workshop: number;
  id_brand: number;
  active:boolean;
  name: string;
  banner_title: string;
  banner_image: string;
  content_title: string;
  content_description: string;
  content_image: string;


    constructor (p?: Page) {

      this.id = p && p.id;
      this.id_page_type = p && p.id_page_type;
      this.id_workshop = p && p.id_workshop;
      this.id_brand = p && p.id_brand;
      this.active = p && p.active;
      this.name = p && p.name;
      this.banner_title = p && p.banner_title;
      this.banner_image = p && p.banner_image;
      this.content_title = p && p.content_title;
      this.content_description = p && p.content_description;
      this.content_image = p && p.content_image;
    }
}
