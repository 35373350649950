//PAGE TYPES
export const PAGE_TYPES_IDS = {
    HOME_PAGE_TYPE_ID: 0,
    VEHICLE_PAGE_TYPE_ID: 1,
    SERVICE_PAGE_TYPE_ID: 2,
    PROMOTION_PAGE_TYPE_ID: 3,
    WHO_WE_ARE_PAGE_TYPE_ID: 4,
    CONTACT_PAGE_TYPE_ID: 5,
    RESERVATION_PAGE_TYPE_ID: 6,
    PARTNER_PAGE_TYPE_ID: 7,
    POLICIES_PAGE_TYPE_ID: 8,
    TERMS_PAGE_TYPE_ID: 9,
    LEGAL_ADVICE_PAGE_TYPE_ID: 10
  }

  export const EDITOR_CONFIG = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Introduce una descripción...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
}

export const icons = {
  vo: "./assets/icons/vo.svg",
  facebook: "./assets/icons/social/facebook.svg",
  twitter: "./assets/icons/social/twitter.svg",
  linkedin: "./assets/icons/social/linkedin.svg"
}

export const social = [{
  id:null,
  id_social_network:1,
  name:'facebook',
  link:'',
  status:false,
  image:icons.facebook
},
{
  id:null,
  id_social_network:2,
  name:'twitter',
  link:'',
  status:false,
  image:icons.twitter
},
{
  id:null,
  id_social_network:3,
  name:'linkedin',
  link:'',
  status:false,
  image:icons.linkedin
}];