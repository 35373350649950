import { Component, OnInit } from '@angular/core';
import { SharingService } from './services/sharing.service';
import { AppconfigService } from './services/appconfig.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'FrontManagementMultisite';
  constructor(
    public _ss:SharingService,
  ){

  }
  
  ngOnInit(){
  }
}
