<div class="form" [ngClass]="{open: _ss.isNewSlider}">
    <div class="title">
        <span class="close" (click)="showSlider()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </span>
        <h1 *ngIf="slider.id">Modificar Slider</h1>
        <h1 *ngIf="!slider.id">Nuevo Slider</h1>
    </div>
    <div class="info-content">
        <label for="title">Título</label>
        <input type="text" name="title" [(ngModel)]="slider.title" placeholder="Título" />
        <label for="title">Descripción</label>
        <angular-editor [placeholder]="'Introduce una descripción...'" [(ngModel)]="slider.description" [config]="editorConfig"></angular-editor>
        <div class="row">
            <label for="active_contact">
                Activar / Desactivar Contacto
            </label>
            <label for="switch">
                
            </label>
        </div>
        <div class="uploading">
            <label for="upload-file" class="custom-file-upload">
            <i class="fa fa-cloud-upload"></i>
          Seleccione una imagen</label>
            <span class="file-name">{{ filename }}</span>
            <input id="upload-file" #selectedPicture type="file" name="photo" ng2FileSelect [uploader]="uploader" />
            <div class="progress" *ngIf="isuploadingProgress">
                <p style="padding: 0; margin: 0">Archivo subido</p>
                <div class="progress-bar" [style.width]="uploadStatusProgress">
                    <p class="progress-content" role="progressbar"></p>
                </div>
                <span class="progress-num">{{ uploadStatusProgress }}</span>
                <!-- <button class="cancelUploadBtn" (click)="cancelUpload()">Cancel</button> -->
            </div>
            <div class="drop-zone" [ngClass]="{'file-over': hasBaseDropZoneOver}" ng2FileDrop [uploader]="uploader" (click)="fileInput.click()">
    
                Por favor, elige una imagen
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" />
            </div>
        </div>
        <button *ngIf="slider.id != null" class="btn" (click)="save()">EDITAR Y GUARDAR</button>
        <button *ngIf="slider.id == null" class="btn" (click)="save()">CREAR Y GUARDAR</button>
    </div>
  
</div>
