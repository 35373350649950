<div class="header">
    
    <select [(ngModel)]="_ss.selectedBrand" (change)="setBrandSettings($event.target.value)">
        <option value="">selecciona un dominio</option>
        <option *ngFor="let item of _ss.getWorkShopSettings()?.domains" [value]="item.id_brand">{{ item.domain }}</option>
    </select>
      
    <h1>{{_ss.titleSection}}</h1>
    <div class="menu-options">
        <a *ngIf="_ss.saveButton" class="menu-btn" (click)="onSave()" #savebutton>
            <div class="icon">
                <img alt="save" src="../../../../assets/icons/toolbar/toolbar-save-and-edit.svg">
            </div>
            Guardar
        </a>
        <a *ngIf="_ss.newButton" class="menu-btn" (click)="onCreate()" #newbutton>
            <div class="icon">
                <img alt="add" src="../../../../assets/icons/toolbar/toolbar-add.svg">
            </div>
            Crear
        </a>
    </div>
</div>