<div id="page-tabs-component">

    <ul class="tabs">
        <li class="page" *ngFor="let page of pages">
            <div class="tab" [ngClass]="page.class" [class.active]="page.checked" *ngIf="page.status" (click)="changeActivePageTab(page.id_page_type)">
                <span>{{page.name}}</span>
            </div>
        </li>
    </ul>
    <div class="tab-container" *ngIf="activePageTab == 0">
        <app-home-details></app-home-details>
    </div>

    <div class="tab-container" *ngIf="activePageTab != 0">
     
        <div class="page-active">
            <label>Activar Página</label>
            <label class="switch">
                <input type="checkbox" (change)="setPageActive()" [checked]="page.active">
                <span class="slider round"></span>
            </label>
        </div>
        
        <app-page-details [idPageType]="activePageTab">
        </app-page-details>
    </div>
</div>
