<form class="form-container" #formVehicle="ngForm" [ngClass]="{open: isFormOpen}">
    <div class="title">
        <h1 *ngIf="vehicle.id == null">Nuevo VO</h1>
        <h1 *ngIf="vehicle.id != null">Modificar VO</h1>
        <span class="close" (click)="closeForm()">
            <fa-icon [icon]="faClose"></fa-icon>
        </span>
    </div>
    <div class="container">
        <div class="tabs-form">
            <ul class="tab-links">
                <li [class.active]="activeTab === 'form'" (click)="selectFormTab('form')">Datos Vehículo</li>
                <li [class.active]="activeTab === 'uploader'" (click)="selectFormTab('uploader')">Galería</li>
            </ul>
        </div>
        <div class="tab-form-content">
            <div *ngIf="activeTab === 'form'">
                        <div class="row space-beetween">
                            <div class="form-group">
                                <label>Marca</label>
                                <input type="text" name="brand" [(ngModel)]="vehicle.brand" [ngClass]="{ 'error': !formVehicle.controls['brand']?.valid && submitted }"  required/>
                                <div class="error-message" *ngIf="!formVehicle.controls['brand']?.valid && submitted">Campo obligatorio</div>
                            </div>
                            <div class="form-group">
                                <label>Modelo</label>
                                <input type="text" name="model" [(ngModel)]="vehicle.model" [ngClass]="{ 'error': !formVehicle.controls['model']?.valid && submitted }" required/>
                                <div class="error-message" *ngIf="!formVehicle.controls['model']?.valid && submitted">Campo obligatorio</div>
                            </div>
                            <div class="form-group">
                                <label>Version</label>
                                <input type="text" name="version" [(ngModel)]="vehicle.version" />
                            </div>
                        </div>
                        <div class="row space-beetween">
                            <div class="form-group">
                                <label>Matrícula</label>
                                <input type="text" name="plate"  [(ngModel)]="vehicle.plate" />
                            </div>
                            <div class="form-group">
                                <label>Fecha Matriculación</label>
                                <input type="date" name="first_date_plate"  [ngModel]="vehicle.first_date_plate | date: 'yyyy-MM-dd'" />
                            </div>
                        </div>
                        <div class="row space-beetween">
                            <div class="form-group">
                                <label>Carrocería</label>
                                <input type="text" name="bodywork"  [(ngModel)]="vehicle.bodywork" />
                            </div>
                            <div class="form-group">
                                <label>Puertas</label>
                                <input type="number" name="doors"  [(ngModel)]="vehicle.doors" />
                            </div>
                            <div class="form-group">
                                <label>Combustible</label>
                                <select name="fuel" [(ngModel)]="vehicle.fuel">
                                    <option value="" disabled selected>selecciona una opción</option>
                                    <option value="Gasolina">Gasolina</option>
                                    <option value="Diésel">Diésel</option>
                                    <option value="Eléctrico">Eléctrico</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Transmisión</label>
                                <select name="transmission"  [(ngModel)]="vehicle.transmission">
                                    <option value="">Seleccione una opción</option>
                                    <option value="Manual">Manual</option>
                                    <option value="Automático">Automático</option>
                                </select>
                            </div>
                        </div>
                        <div class="row space-beetween">
                            <div class="form-group">
                                <label>Watios</label>
                                <input type="number" name="watios"  [(ngModel)]="vehicle.watios" />
                            </div>
                            <div class="form-group">
                                <label>Km</label>
                                <input type="text" name="kilometers"  [(ngModel)]="vehicle.kilometers" />
                            </div>
                            <div class="form-group">
                                <label>Motor</label>
                                <input type="text" name="engine"  [(ngModel)]="vehicle.engine" />
                            </div>
                        </div>
                        <div class="row space-beetween">
                            <div class="form-group">
                                <label>Color</label>
                                <input type="text" name="color"  [(ngModel)]="vehicle.color" />
                            </div>
                            <div class="form-group">
                                <label>Garantía</label>
                                <input type="text" name="warranty"  [(ngModel)]="vehicle.warranty" />
                            </div>
                            <div class="form-group">
                                <label>Precio</label>
                                <input type="text" name="price"  [(ngModel)]="vehicle.price" />
                            </div>
                            <div class="form-group">
                                <label>Precio Oferta</label>
                                <input type="text" name="offered_price"  [(ngModel)]="vehicle.offered_price" />
                            </div>
                        </div>
                        <!-- Tabs-->
                        <div class="tabs">
                            <ul class="tab-links">
                            <li *ngFor="let tab of tabs" [class.active]="tab.active" (click)="selectTab(tab)">
                                <a>{{ tab.label }}</a>
                            </li>
                            </ul>
                        
                            <div class="tab-content">
                            <ng-container *ngFor="let tab of tabs">
                                <div *ngIf="tab.active">
                                {{ tab.content }}
                                </div>
                            </ng-container>
                            </div>
                        </div>
            </div>
            <div *ngIf="activeTab === 'uploader'">
                <div class="image-list">
                    <div *ngFor="let image of vehicle.images"  class="image" [ngStyle]="{'background-image':'url('+ _ss.imgURL + image + ')'}">
                        <span class="del-img" (click)="delImage(image)">
                            <fa-icon [icon]="faClose"></fa-icon>
                        </span>
                    </div>
                </div>
                <!-- Uploader -->
                <div class="uploading">
                    <label for="upload-file" class="custom-file-upload">
                    <i class="fa fa-cloud-upload"></i>
                    Seleccione una imagen</label>
                    <span class="file-name">{{ filename }}</span>
                    <input id="upload-file" #selectedPicture type="file" name="photo" ng2FileSelect [uploader]="uploader" />
                    <div class="progress" *ngIf="isuploadingProgress">
                        <p style="padding: 0; margin: 0">Archivo subido</p>
                        <div class="progress-bar" [style.width]="uploadStatusProgress">
                            <p class="progress-content" role="progressbar"></p>
                        </div>
                        <span class="progress-num">{{ uploadStatusProgress }}</span>
                        <!-- <button class="cancelUploadBtn" (click)="cancelUpload()">Cancel</button> -->
                    </div>
                    <div class="drop-zone" [ngClass]="{'file-over': hasBaseDropZoneOver}" ng2FileDrop [uploader]="uploader" (click)="fileInput.click()">

                        Por favor, elige una imagen
                        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" />
                    </div>
                </div>
                <!-- End uploader -->
            </div>
        </div>
    
    <!-- End Tabs -->
    <button *ngIf="vehicle.id != null" class="btn" (click)="handleSubmit(formVehicle)">EDITAR Y GUARDAR</button>
    <button *ngIf="vehicle.id == null" class="btn" (click)="handleSubmit(formVehicle)">CREAR Y GUARDAR</button>             
    </div>
</form>


  