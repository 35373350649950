import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharingService } from './sharing.service';
import { siteURL } from '../config';
import { Setting } from '../models/settings';
import { ApserviceService } from './apservice.service';

@Injectable({
  providedIn: 'root'
})
export class AppconfigService {
  domain;
  id_workshop;
  private publicURL = siteURL.host_backend + siteURL.endpoint_public;  // siteURL to web api

  constructor(
    public _http: HttpClient,
    public _ss: SharingService,
    private _ap: ApserviceService
  ) {    
  }



public loadConfig(id_workshop) {
    console.log('Cargando configuración site...');
    return this._http.get(this.publicURL+'settings/'+id_workshop)
    .toPromise()
    .then((response: any) => {
      console.log(response.data[0]);
        this._ss.workshopData = response.data[0];         
        this._ss.setLocalStorage(JSON.stringify(this._ss.workshopData));
        this.getDomains();
    })
    .catch((err: any) => {
        console.error(err);
    })
  }


  getDomains(){
    this._ap.getWorkshopDomains().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.data);
        this._ss.workshopData.domains = response.data;
      }else{
        console.log("Se ha producido un error");
      }
    },(err)=>{
      console.log(err);
    });
  }

}
