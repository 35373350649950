export class Domain {
    // Campos obligatorios implementados por la interfaz
    id: number;
    id_workshop: number;
    id_domain: number;
    id_brand: number;
    id_brand_template:number;
    address: string;
    email: string;
    phone: string;
    logo: string;
    site_title: string;
    site_description: string;
    timetable: string;
  
  
      constructor (d?: Domain) {
        // this.sales = a && a.sales || [new Sale()];
        this.id = d && d.id || null;
        this.id_workshop = d && d.id_workshop || null;
        this.id_domain = d && d.id_domain || null;
        this.id_brand = d && d.id_brand || null;
        this.id_brand_template = d && d.id_brand_template || null;
        this.address = d && d.address || '';
        this.email = d && d.email || '';
        this.phone = d && d.phone || '';
        this.logo = d && d.logo || '';
        this.site_title = d && d.site_title || '';
        this.site_description = d && d.site_description || '';
        this.timetable = d && d.timetable || '';
      }
  }
  