import { Component, OnInit } from '@angular/core';
import { ApserviceService } from 'src/app/services/apservice.service';
import { SharingService } from 'src/app/services/sharing.service';
import { NotifierService } from 'angular-notifier';
import { faChevronDown, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home-details',
  templateUrl: './home-details.component.html',
  styleUrls: ['./home-details.component.scss']
})
export class HomeDetailsComponent implements OnInit {

  slider:any = {
    id: null,
    id_workshop: this._ss.getWorkShopSettings().id_workshop,
    title: '',
    description: '',
    image: '',
    status: false,
    active_contact: false
  };
  banner:any = {
    id: null,
    id_workshop: this._ss.getWorkShopSettings().id_workshop,
    title: '',
    description: '',
    image: '',
    status: false,
  };

  isOpenSlider:boolean = true;
  isOpenBanner:boolean = true;

  faChevronDown = faChevronDown;
  faClose = faTimes;
  faPlus = faPlus;

  constructor(
    private _ap: ApserviceService,
    public _ss: SharingService,
    public _ns: NotifierService
  ) { 
    this._ss.titleSection = 'Páginas';
  }

  ngOnInit(): void {
    this.getSliders();
    this.getBanners();
  }


  //SLIDER 

  getSliders(){
    this._ap.getWorkshopSliders().subscribe((response)=>{
      if(response.result = "OK"){
        console.log(response.msg);
       this._ss.sliders = response.data;
        console.log(this._ss.sliders);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    },(err)=>{
      console.log(err);
    });
  }

  changeSliderStatus(id){
    this._ap.changeSliderStatus(id).subscribe((response)=>{
      if(response.result = "OK"){
        console.log(response.msg);
       this._ss.sliders.find(x => x.id == id).status =  !this._ss.sliders.find(x => x.id == id).status;
        if(this._ss.sliders.find(x => x.id == id).status){
          this._ns.notify("success","Activado correctamente!")
        }else{
          this._ns.notify("success","Desactivado correctamente!")
        }
      }else{
        console.log(response.msg);
        this._ns.notify("error","Error: Se ha producido un error al activar/desactivar !");
      }
    },(err)=>{
      console.log("Se ha producido un error", err);
    });
  }

  closeSlider(){
    this.isOpenSlider = !this.isOpenSlider;
  }

  deleteSlider(id){
    this._ap.deleteSlider(id).subscribe((response)=>{
      if(response.result = "OK"){
        console.log(response.msg);
        this._ns.notify("success","Eliminado correctamente!");
       this._ss.sliders =this._ss.sliders.filter(x => x.id != id);
        console.log(this._ss.sliders);
      }else{
        this._ns.notify("error","Error:No se ha podido eliminar!");
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    },(err)=>{
      console.log(err);
    });
  }

  setSlider(slider){
    console.log(slider);
    let slide =this._ss.sliders.find(x => x.id == slider.id);
    this.slider = slide;
    console.log(this.slider);
    this._ss.isNewBanner = false;
    this._ss.isNewSlider = true;
  }

  addSlider(){
    this._ss.isNewBanner = false;
    this._ss.isNewSlider = true;
    console.log("añadiendo un nuevo slider");
    this.slider = {
      id: null,
      id_workshop: this._ss.getWorkShopSettings().id_workshop,
      title: '',
      description: '',
      image: '',
      status: false,
      active_contact: false
    };
  }

  //BANNER

  getBanners(){
    this._ap.getWorkShopBanners().subscribe((response)=>{
      if(response.result = "OK"){
        console.log(response.msg);
        this._ss.banners = response.data;
        console.log(this._ss.banners);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    },(err)=>{
      console.log(err);
    });
  }

  addBanner(){
    this._ss.isNewSlider = false;
    this._ss.isNewBanner = true;
    this.banner = {
      id: null,
      id_workshop: this._ss.getWorkShopSettings().id_workshop,
      title: '',
      description: '',
      image: '',
      status: false
    };
    console.log("añadiendo un nuevo slider");
  }

  setBanner(banner){
    console.log(banner);
    let banne = this._ss.banners.find(x => x.id == banner.id);
    this.banner = banne;
    console.log(this.banner);
    this._ss.isNewBanner = true;
    this._ss.isNewSlider = false;
  }

  changeStatusBanner(id){
    this._ap.changeBannerStatus(id).subscribe((response)=>{
      if(response.result = "OK"){
        console.log(response.msg);
       this._ss.banners.find(x => x.id == id).status =  !this._ss.banners.find(x => x.id == id).status;
        if(this._ss.banners.find(x => x.id == id).status){
          this._ns.notify("success","Activado correctamente!")
        }else{
          this._ns.notify("success","Desactivado correctamente!")
        }
      }else{
        console.log(response.msg);
        this._ns.notify("error","Error: Se ha producido un error al activar/desactivar!");
      }
    },(err)=>{
      console.log("Se ha producido un error", err);
    });
  }

  deleteBanner(id){
    this._ap.deleteBanner(id).subscribe((response)=>{
      if(response.result = "OK"){
        console.log(response.msg);
        this._ns.notify("success","Eliminado correctamente!");
        this._ss.banners = this._ss.banners.filter(x => x.id != id);
        console.log(this._ss.banners);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
        this._ns.notify("error","Error:No se ha podido eliminar!");
      }
    },(err)=>{
      console.log(err);
    });
  }

  closeBanner(){
    this.isOpenBanner = !this.isOpenBanner;
  }

}
