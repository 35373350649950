<div class="form"  [ngClass]="{open: _ss.isNewDomain}">
    <div class="title">
        <span class="close" (click)="showDomain()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </span>
        <h1>Configurar Domínio</h1>
    </div>
    <div class="container">
        <label for="title">Título del sitio</label>
        <input type="text" name="title" [(ngModel)]="domain.site_title" placeholder="Título del sitio" />
        <label for="title">Descripción del sitio</label>
        <input type="text" name="description" [(ngModel)]="domain.site_description" placeholder="Descripción" />
        <label for="title">Plantillas:</label>
        <div class="templates">
            <div class="template-target" [ngClass]="{active: domain.id_brand_template == template.id }" [ngStyle]="{'background-image':'url('+ _ss.imgURL + template.image + ')'}" *ngFor="let template of templates" (click)="setThemeActive(template.id)">
                <h1>{{ template.name }}</h1> 
            </div>
        </div>
        <div class="row">
            <div class="">
                <label for="title">Teléfono</label>
                <input type="text" name="phone" [(ngModel)]="domain.phone" placeholder="(+34) 000 00 00 00" />
            </div>
            <div>
                <label for="title">Correo</label>
                <input type="text" name="email" [(ngModel)]="domain.email" placeholder="tucorreo@tucorreo.es" />
            </div>

        </div>
        <label for="title">Horario</label>
        <input type="text" name="timetable" [(ngModel)]="domain.timetable" placeholder="Horario" />
        <label for="title">Dirección</label>
        <input type="text" name="address" [(ngModel)]="domain.address" placeholder="Dirección" />
    
        <a class="btn" (click)="saveDomain()">EDITAR Y GUARDAR</a>
    </div>
</div>



