import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {



  constructor(
    private router: Router,
    public _ss: SharingService,
  ) { }

  ngOnInit(): void {
    this._ss.selectedBrand = this._ss.getWorkShopSettings()?.id_brand || "";
    this._ss.setSelectedBrandId(this._ss.selectedBrand);
  }

  onSave() {
    this._ss.saveEvent();
  }

  onCreate() {
    this._ss.createEvent();
  }

  setBrandSettings(e) {
    console.log("haciendo el cambio");
    this._ss.workshopData.id_brand = e;
    this._ss.selectedBrand = e;
    this._ss.setSelectedBrandId(e);
    this._ss.setLocalStorage(JSON.stringify(this._ss.workshopData));
    if (!this.router.url.includes('/configuracion')) {
      // Recargar la página solo si la ruta no es "/configuración"
      this.router.navigate([this.router.url]);
    }
  }
}
