import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Vehicle } from "../../../models/vehicle";
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-vovehicle',
  templateUrl: './vovehicle.component.html',
  styleUrls: ['./vovehicle.component.scss']
})
export class VovehicleComponent implements OnInit {

  @Input() vehicle: Vehicle;
  @Output() editClicked = new EventEmitter<any>();
  @Output() delClicked = new EventEmitter<any>();
  public faClose = faTimes;
  public faStar = faStar;
  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    public _ns: NotifierService
  ) { }

  ngOnInit(): void {
  }

  updateStatus(active, featured) {
    this.vehicle = { ...this.vehicle, active: active ? 1 : 0, featured: featured ? 1 : 0, first_date_plate: new Date(this.vehicle.first_date_plate)
      .toISOString()
      .slice(0, 19)
      .replace('T', ' '), };
    this._ap.modifyVoVehicle(this.vehicle).subscribe((response) => {
      if(response.result === 'OK'){

      }else{

      }
    });
  }

  delPromotion(vehicle){
    this._ap.deleteVoVehicle(vehicle).subscribe((response) => {
      if(response.result === 'OK'){
        this._ns.notify('success', 'Eliminado correctamente');
        this.delClicked.emit();
      }else{
        this._ns.notify('error', 'Campos obligatorios');
      }
    },((error) => {
      this._ns.notify('error', 'Campos obligatorios');
    }))
  }
  
  openForm(){
    const editVehicle = new Vehicle(this.vehicle);
    this.editClicked.emit(editVehicle);
  }
}
